import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  MenuIcon,
  XIcon,
  CurrencyEuroIcon,
  ShoppingBagIcon,
  SwitchVerticalIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useParams } from "react-router-dom";
import { useTranslation } from "../services/i18n";
import LanguageSelector from "./_language-selector";
import {
  getWebsiteHost,
  getWebsiteLogoPrefix,
  getWebsiteName,
} from "../services/site-name";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default (props: { dark?: boolean }) => {
  const router = useParams();
  const { t } = useTranslation({
    en: {
      "solutions.crypto_wallet": "Crypto Wallet",
      "solutions.crypto_wallet.description":
        "Manage your coins quickly and easily.",
      "solutions.merchants": "Merchants",
      "solutions.merchants.description":
        "Accept customers crypto payments in seconds.",
      "solutions.otc": "OTC",
      "solutions.otc.description":
        "Our legal team and licenses at your service.",
      "solutions.more": "Need more?",
      "solutions.more.description":
        "Contact us and we will tailor " + getWebsiteName() + " to your needs.",
      "contact.title": "Contact us",
      "signin.title": "Sign in",
      "signup.title": "Sign up",
    },
    fr: {
      "solutions.crypto_wallet": "Crypto Wallet",
      "solutions.crypto_wallet.description":
        "Gérez vos cryptos facilement et rapidement.",
      "solutions.merchants": "E-Commerce",
      "solutions.merchants.description":
        "Acceptez les paiements cryptos de vos clients en quelques secondes.",
      "solutions.otc": "OTC",
      "solutions.otc.description":
        "Notre équipe légale et nos licences à votre service.",
      "solutions.more": "En avoir plus?",
      "solutions.more.description":
        "Contactez-nous et nous nous ferons un plaisir de personnaliser " +
        getWebsiteName() +
        " pour vous.",
      "contact.title": "Contactez-nous",
      "signin.title": "Se connecter",
      "signup.title": "S'inscrire",
    },
    bg: {
      "solutions.crypto_wallet": "Крипто Портфейл",
      "solutions.crypto_wallet.description":
        "Управлявайте вашите крипто валути бързо и лесно.",
      "solutions.merchants": "Електронна търговия",
      "solutions.merchants.description":
        "Приемайте крипто плащания от вашите клиенти в рамките на секунди.",
      "solutions.otc": "OTC",
      "solutions.otc.description":
        "Нашите юридически екип и лицензи на ваше разположение.",
      "solutions.more": "Нуждаете се от повече?",
      "solutions.more.description":
        "Свържете се с нас и ще персонализираме " +
        getWebsiteName() +
        " според вашите нужди.",
      "contact.title": "Свържете се с нас",
      "signin.title": "Вход",
      "signup.title": "Регистрация",
    },
  });

  const solutions = [
    {
      name: t("solutions.crypto_wallet"),
      description: t("solutions.crypto_wallet.description"),
      href: "/solutions/wallet",
      icon: CurrencyEuroIcon,
    },
    {
      name: t("solutions.merchants"),
      description: t("solutions.merchants.description"),
      href: "/solutions/merchant",
      icon: ShoppingBagIcon,
    },
    {
      name: t("solutions.otc"),
      description: t("solutions.otc.description"),
      href: "/solutions/otc",
      icon: SwitchVerticalIcon,
    },
  ];

  const [onTop, setOnTop] = useState(true);
  if (typeof window !== "undefined") {
    window.onscroll = function (_) {
      const isOntop = window.scrollY < 50;
      if (isOntop !== onTop) setOnTop(isOntop);
    };
  }

  return (
    <header>
      <div
        className="relative flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8"
        style={{ height: "90px" }}
      ></div>
      <Popover className={"header bg-white " + (onTop ? "" : "shadow")}>
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="">
            <a href={"/" + (router.locale || "en") + "/"}>
              <span className="sr-only">Logo</span>
              <img
                className="h-6 w-auto sm:h-6"
                src={"/assets/" + getWebsiteLogoPrefix() + "logo_text.svg"}
                alt=""
              />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-700">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900"
                    )}
                  >
                    <span>Solutions</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-md lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {solutions.map((item) => (
                            <a
                              key={item.name}
                              href={"/" + (router.locale || "en") + item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-blue-600"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="p-5 bg-gray-50 sm:p-8">
                          <a
                            href={"/" + (router.locale || "en") + "/contact"}
                            className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100"
                          >
                            <div className="flex items-center">
                              <div className="text-base font-medium text-gray-900">
                                {t("solutions.more")}
                              </div>
                            </div>
                            <p className="mt-1 text-sm text-gray-500">
                              {t("solutions.more.description")}
                            </p>
                          </a>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <a
              href={"/" + (router.locale || "en") + "/contact"}
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              {t("contact.title")}
            </a>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <LanguageSelector iconOnly />

            <a
              href={"https://wallet." + getWebsiteHost()}
              className="ml-8 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
            >
              {t("signin.title")}
            </a>
            <a
              href={"https://wallet." + getWebsiteHost() + "/signup"}
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              {t("signup.title")}
            </a>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={
                        "/assets/" + getWebsiteLogoPrefix() + "logo_text.svg"
                      }
                      alt="Workflow"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-700">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid grid-cols-1 gap-7">
                    {solutions.map((item) => (
                      <a
                        key={item.name}
                        href={"/" + (router.locale || "en") + item.href}
                        className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                      >
                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-indigo-700 to-blue-600 text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4 text-base font-medium text-gray-900">
                          {item.name}
                        </div>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
};
