import {
  CurrencyEuroIcon,
  ShoppingBagIcon,
  SwitchVerticalIcon,
} from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import { useTranslation } from "../services/i18n";
import { getWebsiteName } from "../services/site-name";
import { WhiteLabel } from "../white-label";

export default function Services() {
  const router = useParams();
  const { t } = useTranslation({
    en: {
      ...WhiteLabel.locale.services.en,
      "solutions.more.description":
        "Contact us and we will tailor " + getWebsiteName() + " to your needs.",
    },
    fr: {
      ...WhiteLabel.locale.services.fr,
      "solutions.more.description":
        "Contactez-nous et nous nous ferons un plaisir de personnaliser " +
        getWebsiteName() +
        " pour vous.",
    },
    bg: {
      ...WhiteLabel.locale.services.bg,
      "solutions.more.description":
        "Свържете се с нас и ще персонализираме " +
        getWebsiteName() +
        " според вашите нужди.",
    },
  });

  const features = [
    {
      name: t("solutions.crypto_wallet"),
      description: t("solutions.crypto_wallet.description"),
      href: "/solutions/wallet",
      icon: CurrencyEuroIcon,
    },
    {
      name: t("solutions.merchants"),
      description: t("solutions.merchants.description"),
      href: "/solutions/merchant",
      icon: ShoppingBagIcon,
    },
    {
      name: t("solutions.otc"),
      description: t("solutions.otc.description"),
      href: "/solutions/otc",
      icon: SwitchVerticalIcon,
    },
  ];

  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold uppercase tracking-wider text-indigo-600">
          {t("title")}
        </h2>
        <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
          {t("description")}
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-indigo-500 p-3 shadow-lg">
                        <feature.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
